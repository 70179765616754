<template>
    <transition name="fade">
        <div
            v-if="show"
            class="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-20 p-4 overflow-y-auto"
            @click="$emit('close')"
        >
            <div
                class="bg-white mt-20 rounded-xl max-w-3xl p-8 mx-auto"
                @click.stop=""
            >
                <div class="relative">
                    <svg
                        class="absolute right-0 top-0 cursor-pointer"
                        height="24"
                        version="1.0"
                        viewBox="0 0 64 64"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="$emit('close')"
                    >
                        <g
                            fill="#000000"
                            stroke="none"
                            transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                        >
                            <path
                                d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                            />
                        </g>
                    </svg>
                </div>
                <span
                    class="font-bold text-lg"
                >{{ isCreationForm ? 'Anbaugerät hinzufügen' : 'Anbaugerät bearbeiten' }}</span>
                <div class="mt-4">
                    <div>
                        <div>
                            <label
                                class="font-bold"
                                for="equipment_id"
                            >ID des Anbaugerätes:
                                <router-link
                                    v-show="!isCreationForm"
                                    :to="{name:'adminEquipmentDetail', params: {id: form.equipment_id}}"
                                >
                                    Link
                                </router-link>

                            </label>
                            <input
                                id="equipment_id"
                                v-model="form.equipment_id"
                                :class="[(!validations.equipment_id) ? 'border-red' : 'border-gray-200', isCreationForm ? 'bg-white' : 'bg-gray-100']"
                                :readonly="!isCreationForm"
                                class="w-full p-3 mt-1/2 rounded-md border-2"
                                placeholder="1"
                                type="text"
                            >
                            <span
                                v-show="!validations.equipment_id"
                                class="font-bold text-red"
                            >Die ID wurde nicht gefunden!</span>
                            <span
                                v-show="!validations.equipment_id_compatible"
                                class="font-bold text-red"
                            >Das Anbaugerät ist nicht kompatibel!</span>
                        </div>
                        <div>
                            <label
                                class="font-bold"
                                for="active_from"
                            >Aktiv von:</label>
                            <input
                                id="active_from"
                                v-model="form.active_from"
                                :class="[(!validations.active_from) ? 'border-red' : 'border-gray-200', ((new Date(rentEquipment.active_from) >= new Date() || isCreationForm) ? 'bg-white': 'bg-gray-100')]"
                                :readonly="(new Date(rentEquipment.active_from) < new Date()) && !isCreationForm"
                                class="w-full p-3 mt-1/2 rounded-md border-2"
                                type="date"
                            >
                        </div>
                        <div>
                            <label
                                class="font-bold"
                                for="active_to"
                            >Aktiv bis:</label>
                            <input
                                id="active_to"
                                v-model="form.active_to"
                                :class="[(!validations.active_to) ? 'border-red' : 'border-gray-200', ((new Date(rentEquipment.active_to) >= new Date() || isCreationForm) ? 'bg-white': 'bg-gray-100')]"
                                :readonly="(new Date(rentEquipment.active_to) < new Date()) && !isCreationForm"
                                class="w-full p-3 mt-1/2 rounded-md border-2"
                                type="date"
                            >
                        </div>
                    </div>
                    <div class="flex justify-center mt-4">
                        <spin-button
                            :loading="sendingRequest"
                            :text="isCreationForm ? 'Anbaugerät erstellen' : 'Änderung speichern'"
                            class="bg-red text-white w-52 h-12"
                            @btnClick="sendRequest"
                        />
                    </div>
                </div>
                <div v-show="!isCreationForm">
                    <price-table
                        :id="rentId"
                        :data="currentEquipmentPrices"
                        :is-admin="true"
                        :rent-equipment-id="rentEquipment.id"
                        class="px-2 py-10"
                        type="rentEquipment"
                        @create="addRentEquipmentPrice"
                        @delete="removeRentEquipmentPrice"
                    />
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import {
    createRentEquipment,
    getRentEquipmentPrices,
    updateRentEquipment,
} from '@/js/services/rent.service';
import SpinButton from '@/js/components/generic/buttons/SpinButton.vue';
import { isValidDate } from '@/js/services/helper.service';
import PriceTable from '@/js/components/page/price/PriceTable.vue';

const { DateTime } = require('luxon');

export default {
    components: {
        SpinButton,
        PriceTable,
    },
    props: {
        rentId: {
            type: Number,
            required: true,
        },
        rentEquipment: {
            type: Object,
            default: () => {
            },
        },
        show: {
            type: Boolean,
            required: true,
            default: () => false,
        },
    },
    data() {
        return {
            form: {
                equipment_id: -1,
                active_from: '',
                active_to: '',
            },
            oldForm: {},
            validations: {
                equipment_id: true,
                equipment_id_compatible: true,
                active_from: true,
                active_to: true,
            },
            currentEquipmentPrices: [],
            sendingRequest: false,
        };
    },
    computed: {
        isCreationForm() {
            return Object.keys(this.rentEquipment).length === 0;
        },
    },
    watch: {
        rentEquipment: {
            immediate: false,
            handler(value) {
                if (!this.isCreationForm) {
                    this.form = {
                        equipment_id: value.equipment.id,
                        active_from: DateTime.fromISO(value.active_from)
                            .toISODate(), // apply timezone
                        active_to: DateTime.fromISO(value.active_to)
                            .toISODate(), // apply timezone
                    };
                    this.oldForm = JSON.parse(JSON.stringify(this.form)); // Deep shallow copy

                    getRentEquipmentPrices(this.rentId, value.id)
                        .then((result) => {
                            this.currentEquipmentPrices = result;
                        });
                } else {
                    this.form = {
                        equipment_id: -1,
                        active_from: '',
                        active_to: '',
                    };
                }
            },
        },
    },
    methods: {
        sendRequest() {
            if (JSON.stringify(this.form) === JSON.stringify(this.oldForm) && !this.isCreationForm) {
                this.$emit('close');
                return;
            }

            this.validations.equipment_id = true;
            this.validations.equipment_id_compatible = true;
            this.validations.active_from = isValidDate(this.form.active_from);
            this.validations.active_to = isValidDate(this.form.active_to);

            if (this.sendingRequest || !this.validations.active_from || !this.validations.active_to) return;
            this.sendingRequest = true;

            let fnc;
            const formattedForm = {
                equipment_id: this.form.equipment_id,
                active_from: DateTime.fromISO(this.form.active_from)
                    .startOf('day')
                    .toISO(), // add timezone
                active_to: DateTime.fromISO(this.form.active_to)
                    .endOf('day')
                    .toISO(), // add timezone
            };

            if (this.isCreationForm) {
                fnc = createRentEquipment(this.rentId, formattedForm)
                    .then((result) => {
                        this.$emit('create', result);
                        this.$emit('close');

                        this.sendingRequest = false;
                    });
            } else {
                this.oldForm = JSON.parse(JSON.stringify(this.form)); // Deep shallow copy

                fnc = updateRentEquipment(this.rentId, this.rentEquipment.id, formattedForm)
                    .then((result) => {
                        Object.keys(this.validations)
                            .forEach((key) => {
                                this.validations[key] = true;
                            });
                        this.sendingRequest = false;
                        this.$emit('update', result);
                        this.$emit('close');
                    });
            }
            fnc.catch((error) => {
                const errors = error.response.data.error;
                if (errors) {
                    if (errors.equipment_id) {
                        if (errors.equipment_id[0] === 'This equipment_id is not compatible with the vehicle.') {
                            this.validations.equipment_id_compatible = false;
                        } else {
                            this.validations.equipment_id = false;
                        }
                    }
                }
                this.sendingRequest = false;
            });
        },
        addRentEquipmentPrice(price) {
            this.currentEquipmentPrices.push(price);
        },
        removeRentEquipmentPrice(priceId) {
            this.currentEquipmentPrices = this.currentEquipmentPrices.filter((e) => e.id !== priceId);
        },
    },
};
</script>
