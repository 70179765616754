<template>
    <div>
        <div class="bg-red flex justify-around sm:justify-between items-center">
            <router-link to="/">
                <img
                    class="w-auto h-12 px-3 my-2 sm:my-0"
                    src="/img/lindner_logo.png"
                >
            </router-link>
            <div class="bg-gray-500 float-right sm:block hidden">
                <router-link to="/dashboard">
                    <img
                        class="w-auto h-16"
                        src="/img/flotte_logo.png"
                    >
                </router-link>
            </div>
        </div>
        <div
            v-if="isAdmin"
            class="bg-gray-200 px-4"
        >
            <router-link :to="{name: 'adminUsers'}">
                Nutzerverwaltung
            </router-link>
            <router-link :to="{name: 'adminRents'}">
                Mietverwaltung
            </router-link>
            <router-link :to="{name: 'adminVehicles'}">
                Fahrzeugverwaltung
            </router-link>
            <router-link :to="{name: 'adminEquipment'}">
                Anbaugerätverwaltung
            </router-link>
            <router-link :to="{name: 'adminDeliveries'}">
                Lieferungverwaltung
            </router-link>
            <router-link :to="{name: 'adminCoupons'}">
                Gutscheinverwaltung
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: 'NavBar',
    computed: {
        isAdmin() {
            return this.$store.getters['user/isAdmin'];
        },
    },
};
</script>
