<template>
    <div>
        <loading-spinner v-if="userLoading || rentLoading" />
        <transition name="fade">
            <div v-if="!userLoading && !rentLoading">
                <div class="mx-8 lg:mx-32">
                    <div class="mb-16 mt-8 mx-0 xl:mx-20 2xl:mx-40">
                        <div class="flex flex-col items-center w-full">
                            <div
                                class="w-full sm:w-3/4 md:3/5 xl:w-2/3 2xl:w-1/2 mb-10 text-xl text-center"
                            >
                                <span class="font-bold">{{
                                    user.firstname + ' ' + user.lastname
                                }}</span><br>
                                <span class="text-sm sm:text-lg">{{ user.email }}</span><br>
                                <span class="text-sm">{{ user.street }}, {{
                                    user.zip
                                }}, {{ user.city }}, {{ user.country }}</span>
                            </div>
                            <div class="justify-between w-full xl:w-2/3 2xl:w-1/2 hidden lg:flex">
                                <div class="text-center">
                                    <span class="font-bold">M3 Kundennummer</span><br>
                                    {{ user.m3_user_id || 'Nicht zugewiesen' }}
                                </div>
                                <div class="text-center hidden sm:block">
                                    <span class="font-bold">Letzte Aktivität</span><br>
                                    {{ formatDate(user.last_active_at, true) }}
                                </div>
                                <div class="text-center">
                                    <span class="font-bold">Rolle</span><br>
                                    {{ user.role }}
                                </div>
                                <div class="text-center">
                                    <span class="font-bold">Telefon-Nr.</span><br>
                                    {{ user.phone || 'Nicht zugewiesen' }}
                                </div>
                            </div>
                            <table class="lg:hidden">
                                <tr>
                                    <td class="font-bold pr-2">
                                        M3 Kundennummer:
                                    </td>
                                    <td>{{ user.m3_user_id || 'Nicht zugewiesen' }}</td>
                                </tr>
                                <tr>
                                    <td class="font-bold pr-2">
                                        Letzte Aktivität:
                                    </td>
                                    <td>{{ formatDate(user.last_active_at, true) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-bold pr-2">
                                        Rolle:
                                    </td>
                                    <td>{{ user.role }}</td>
                                </tr>
                                <tr>
                                    <td class="font-bold pr-2">
                                        Telefon-Nr.:
                                    </td>
                                    <td>{{ user.phone || 'Nicht zugewiesen' }}</td>
                                </tr>
                            </table>
                            <coupon-badge
                                v-if="userCoupons.length > 0"
                                class="mt-6"
                                :coupon="userCoupons[0]"
                                :admin="true"
                                @delete="deletedCoupon"
                            />
                        </div>
                    </div>
                </div>
                <div class="bg-gray-100 px-8 lg:px-32 py-14 flex flex-col">
                    <div class="bg-gray-100 flex flex-col">
                        <p class="text-red font-bold text-xl text-center lg:text-left mb-4">
                            Aktuelle Mieten
                        </p>
                        <rent-table
                            v-show="rents.length > 0"
                            :data="rents"
                            type="admin"
                        />
                        <div
                            v-show="rents.length === 0"
                            class="font-bold text-center lg:text-left"
                        >
                            Derzeit sind keine Mieten vorhanden
                        </div>
                    </div>
                </div>
                <div class="px-8 lg:px-32 py-14 flex justify-center">
                    <button
                        class="bg-red text-white px-6 py-2 text-lg"
                        @click="showForm = true"
                    >
                        Nutzer bearbeiten
                    </button>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div
                v-if="showForm"
                class="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-20 p-4 overflow-y-auto"
                @click="showForm = false"
            >
                <div
                    class="bg-white mt-20 rounded-xl max-w-3xl p-8 mx-auto"
                    @click.stop=""
                >
                    <div class="relative">
                        <svg
                            class="absolute right-0 top-0 cursor-pointer"
                            height="24"
                            version="1.0"
                            viewBox="0 0 64 64"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                            @click="showForm = false"
                        >
                            <g
                                fill="#000000"
                                stroke="none"
                                transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                            >
                                <path
                                    d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                                />
                            </g>
                        </svg>
                    </div>
                    <span
                        class="font-bold text-lg"
                    >Bearbeite {{ user.firstname + ' ' + user.lastname }}</span>
                    <div class="mt-4">
                        <div>
                            <div>
                                <label
                                    class="font-bold"
                                    for="firstname"
                                >Vorname</label>
                                <input
                                    id="firstname"
                                    v-model="form.firstname"
                                    class="w-full p-2 border-2 rounded-md my-1"
                                    placeholder="Max"
                                    type="text"
                                >
                            </div>
                            <div>
                                <label
                                    class="font-bold"
                                    for="lastname"
                                >Nachname</label>
                                <input
                                    id="lastname"
                                    v-model="form.lastname"
                                    class="w-full p-2 border-2 rounded-md my-1"
                                    placeholder="Mustermann"
                                    type="text"
                                >
                            </div>
                            <div>
                                <label
                                    class="font-bold"
                                    for="email"
                                >E-Mail</label>
                                <input
                                    id="email"
                                    v-model="form.email"
                                    class="w-full p-2 border-2 rounded-md my-1"
                                    placeholder="maxmustermann@gmail.com"
                                    type="text"
                                >
                            </div>
                            <div>
                                <label
                                    class="font-bold"
                                    for="m3_user_id"
                                >M3 Kundennummer</label>
                                <input
                                    id="m3_user_id"
                                    v-model="form.m3_user_id"
                                    :class="[(!validations.m3_user_id) ? 'border-red' : 'border-gray-200']"
                                    class="w-full p-2 border-2 rounded-md my-1"
                                    placeholder="0051654"
                                    type="text"
                                >
                            </div>
                            <div>
                                <label
                                    class="font-bold"
                                    for="phone"
                                >Telefon-Nr.</label>
                                <input
                                    id="phone"
                                    v-model="form.phone"
                                    :class="[(!validations.phone) ? 'border-red' : 'border-gray-200']"
                                    class="w-full p-2 border-2 rounded-md my-1"
                                    placeholder="+43 5338 74 20 180"
                                >
                            </div>
                            <div>
                                <label class="font-bold">Rolle</label>
                                <div
                                    class="dropdown inline-block relative h-full border-2 w-full rounded-md"
                                >
                                    <button
                                        class="bg-gray-100 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center justify-between w-full"
                                        type="button"
                                    >
                                        <span class="mr-1">{{ form.role }}</span>
                                        <svg
                                            class="fill-current h-4 w-4"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                            />
                                        </svg>
                                    </button>
                                    <ul class="dropdown-menu absolute hidden text-gray-700 pt-1 w-full">
                                        <li>
                                            <a
                                                class="rounded-t bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap w-full"
                                                @click="form.role = 'User'"
                                            >User</a>
                                        </li>
                                        <li>
                                            <a
                                                class="rounded-b bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap w-full"
                                                @click="form.role = 'Admin'"
                                            >Admin</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <hr class="my-3">
                        <div>
                            <div>
                                <label
                                    class="font-bold"
                                    for="street"
                                >Straße</label>
                                <input
                                    id="street"
                                    v-model="form.street"
                                    class="w-full p-2 border-2 rounded-md my-1"
                                    placeholder="Muster Straße 1"
                                    type="text"
                                >
                            </div>
                            <div>
                                <label
                                    class="font-bold"
                                    for="city"
                                >Ort</label>
                                <input
                                    id="city"
                                    v-model="form.city"
                                    class="w-full p-2 border-2 rounded-md my-1"
                                    placeholder="Wien"
                                    type="text"
                                >
                            </div>
                            <div>
                                <label
                                    class="font-bold"
                                    for="zip"
                                >Postleitzahl</label>
                                <input
                                    id="zip"
                                    v-model="form.zip"
                                    class="w-full p-2 border-2 rounded-md my-1"
                                    placeholder="1010"
                                    type="text"
                                >
                            </div>
                            <div>
                                <label
                                    class="font-bold"
                                    for="country"
                                >Land</label>
                                <input
                                    id="country"
                                    v-model="form.country"
                                    :class="[(!validations.country) ? 'border-red' : 'border-gray-200']"
                                    class="w-full p-2 border-2 rounded-md my-1"
                                    placeholder="AT"
                                    type="text"
                                >
                            </div>
                        </div>
                        <div class="flex justify-center mt-4">
                            <spin-button
                                :loading="sendingRequest"
                                class="bg-red text-white w-52 h-12"
                                text="Änderung speichern"
                                @btnClick="sendRequest"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import { getUser, getUserRents, updateUser } from '@/js/services/user.service';
import RentTable from '@/js/components/page/RentTable.vue';
import LoadingSpinner from '@/js/components/generic/LoadingSpinner.vue';
import SpinButton from '@/js/components/generic/buttons/SpinButton.vue';
import CouponBadge from '@/js/components/page/rentDetail/CouponBadge.vue';
import { formatDate } from '@/js/services/helper.service';

export default {
    name: 'UserDetail',
    components: {
        CouponBadge,
        LoadingSpinner,
        RentTable,
        SpinButton,
    },
    data() {
        return {
            userLoading: true,
            rentLoading: true,
            user: {},
            rents: [],
            showForm: false,
            sendingRequest: false,
            form: {
                firstname: '',
                lastname: '',
                email: '',
                role: '',
                phone: '',
                m3_user_id: '',
                street: '',
                city: '',
                zip: '',
                country: '',
            },
            validations: {
                country: true,
                m3_user_id: true,
                phone: true,
            },
        };
    },
    computed: {
        userCoupons() {
            return this.user.active_coupons.filter((x) => x.rent_id === null);
        },
    },
    mounted() {
        // Check if needed params are passed
        const userId = parseInt(this.$route.params.id, 10);

        if (userId != this.$route.params.id) this.$router.push({ name: 'dashboard' }); // eslint-disable-line eqeqeq

        // Get user data
        getUser(userId)
            .then((user) => {
                this.user = user;
                this.form = { ...user }; // Clone without reference
                this.userLoading = false;
            })
            .catch(() => {
                // User not found
                this.$router.push({ name: 'dashboard' });
            });
        getUserRents(userId)
            .then((rents) => {
                this.rents = rents;
                this.rentLoading = false;
            });
    },
    methods: {
        formatDate,
        sendRequest() {
            if (JSON.stringify(this.user) === JSON.stringify(this.form)) this.showForm = false;
            if (!this.showForm || this.sendingRequest) return;
            this.sendingRequest = true;

            updateUser(this.user.id, this.form)
                .then((result) => {
                    this.user = result;
                    this.showForm = false;
                    this.sendingRequest = false;
                    this.validations.country = true;
                    this.validations.m3_user_id = true;
                })
                .catch((error) => {
                    const errors = error.response.data.error;
                    if (errors) {
                        if (errors.country) {
                            this.validations.country = false;
                        }
                        if (errors.m3_user_id) {
                            this.validations.m3_user_id = false;
                        }
                        if (errors.phone) {
                            this.validations.phone = false;
                        }
                    }
                    this.sendingRequest = false;
                });
        },
        deletedCoupon(coupon) {
            this.user.active_coupons = this.user.active_coupons.filter((x) => x.id !== coupon.id);
        },
    },
};
</script>
