<template>
    <div>
        <loading-spinner v-if="loading" />
        <transition name="fade">
            <div v-if="!loading">
                <div class="mx-8 lg:mx-32">
                    <div
                        class="flex mb-16 mt-16 flex-col lg:flex-row lg:items-center mx-0 xl:mx-20 2xl:mx-40"
                    >
                        <div class="flex flex-col items-center">
                            <img
                                :src="equipment.details.image"
                                class="w-40 lg:w-80"
                            >
                        </div>
                        <div class="lg:pl-32 flex flex-col items-center w-full">
                            <div
                                class="flex items-center flex-col lg:flex-row lg:items-baseline mb-2 text-2xl text-center font-bold"
                            >
                                {{ equipment.details.name }}
                            </div>
                            <table>
                                <tr>
                                    <td class="font-bold">
                                        ID:
                                    </td>
                                    <td class="text-right">
                                        {{ equipment.id }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-bold">
                                        Serialnr.:
                                    </td>
                                    <td class="text-right">
                                        {{ equipment.serial_id }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-bold">
                                        Name:
                                    </td>
                                    <td class="text-right">
                                        {{ equipment.name }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-bold">
                                        Produktionsjahr:
                                    </td>
                                    <td class="text-right">
                                        {{ equipment.production_year }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-bold">
                                        Marke:
                                    </td>
                                    <td class="text-right">
                                        {{ equipment.details.brand || 'Nicht zugewiesen' }}
                                    </td>
                                </tr>
                            </table>
                            <span
                                v-show="equipment.deleted_at !== null"
                                class="font-bold text-red mt-4"
                            >Das Anbaugerät ist derzeit gesperrt</span>
                        </div>
                    </div>
                </div>
                <price-table
                    :id="equipment.id"
                    :data="prices"
                    :is-admin="true"
                    class="bg-gray-100 px-2 lg:px-32 py-14"
                    type="equipment"
                    @create="addPrice"
                    @delete="deletePrice"
                />
                <div class="px-2 lg:px-32 py-14">
                    <p class="text-red font-bold text-xl text-center lg:text-left mb-4">
                        Mieten
                    </p>
                    <rent-equipment-table
                        v-show="rents.length > 0"
                        :data="rents"
                    />
                    <div
                        v-show="rents.length === 0"
                        class="font-bold text-center lg:text-left"
                    >
                        Derzeit sind keine Mieten vorhanden
                    </div>
                </div>
                <div
                    class="px-8 lg:px-32 py-14 flex items-center sm:justify-center flex-col sm:flex-row"
                >
                    <button
                        class="bg-red text-white px-6 py-2 text-lg sm:mr-2 w-60 mb-2 sm:mb-0 h-12"
                        @click="showEquipmentForm = true"
                    >
                        Equipment bearbeiten
                    </button>
                    <spin-button
                        :loading="sendingDelRequest"
                        :text="(equipment.deleted_at === null) ? 'Anbaugerät sperren' : 'Anbaugerät entsperren'"
                        class="bg-gray-400 text-white w-52 h-12 sm:ml-2"
                        @btnClick="setDeleted"
                    />
                </div>
            </div>
        </transition>
        <equipment-form
            :equipment="equipment"
            :show="showEquipmentForm"
            @close="showEquipmentForm = false"
            @update="updateEquipment"
        />
    </div>
</template>
<script>
import {
    deleteEquipment,
    getEquipment,
    getEquipmentPrices,
    getEquipmentRents,
    updateEquipment,
} from '@/js/services/equipment.service';
import LoadingSpinner from '@/js/components/generic/LoadingSpinner.vue';
import SpinButton from '@/js/components/generic/buttons/SpinButton.vue';
import PriceTable from '@/js/components/page/price/PriceTable.vue';
import RentEquipmentTable from '@/js/components/page/RentEquipmentTable.vue';
import EquipmentForm from '@/js/components/forms/equipment/EquipmentForm.vue';

export default {
    name: 'RentDetail',
    components: {
        LoadingSpinner,
        PriceTable,
        RentEquipmentTable,
        EquipmentForm,
        SpinButton,
    },
    data() {
        return {
            loading: true,
            showEquipmentForm: false,
            sendingDelRequest: false,
            equipment: {},
            prices: [],
            rents: [],
        };
    },
    computed: {
        equipmentId() {
            return parseInt(this.$route.params.id, 10);
        },
    },
    mounted() {
        // Check if needed params are passed
        if (this.equipmentId != this.$route.params.id) this.$router.push({ name: 'dashboard' }); // eslint-disable-line eqeqeq

        const equipmentPromise = getEquipment(this.equipmentId);

        const pricePromise = getEquipmentPrices(this.equipmentId)
            .then((prices) => prices.sort((a, b) => new Date(a.active_from) - new Date(b.active_from)));

        const rentPromise = getEquipmentRents(this.equipmentId, {
            opt_fields: ['user'],
        });

        Promise.all([equipmentPromise, pricePromise, rentPromise])
            .then((result) => {
                [this.equipment, this.prices, this.rents] = result;
                this.loading = false;
            })
            .catch(() => {
                this.$router.push({ name: 'dashboard' });
            });
    },
    methods: {
        updateEquipment(result) {
            this.equipment = result;
        },
        addPrice(price) {
            this.prices.push(price);
            this.prices.sort((a, b) => new Date(a.active_from) - new Date(b.active_from));
        },
        deletePrice(priceId) {
            this.prices = this.prices.filter((price) => price.id !== priceId);
        },
        setDeleted() {
            if (this.sendingDelRequest) return;
            this.sendingDelRequest = true;

            if (this.equipment.deleted_at === null) {
                deleteEquipment(this.equipment.id)
                    .then(() => {
                        this.equipment.deleted_at = new Date();
                        this.sendingDelRequest = false;
                    });
            } else {
                updateEquipment(this.equipment.id, {
                    deleted_at: null,
                })
                    .then(() => {
                        this.equipment.deleted_at = null;
                        this.sendingDelRequest = false;
                    });
            }
        },
    },
};
</script>
