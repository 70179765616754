import Search from '@/js/pages/Search.vue';
import Selection from '@/js/pages/Selection.vue';
import Dashboard from '@/js/pages/Dashboard.vue';
import RentDetail from '@/js/pages/RentDetail.vue';
import AuthCallback from '@/js/pages/auth/AuthCallback.vue';
import Auth from '@/js/pages/auth/Auth.vue';
import AdminUsers from '@/js/pages/admin/user/Users.vue';
import AdminUserDetail from '@/js/pages/admin/user/UserDetail.vue';
import Rents from '@/js/pages/admin/rent/Rents.vue';
import AdminRentDetail from '@/js/pages/admin/rent/RentDetail.vue';
import Vehicles from '@/js/pages/admin/vehicle/Vehicles.vue';
import AdminVehicleDetail from '@/js/pages/admin/vehicle/VehicleDetail.vue';
import Equipment from '@/js/pages/admin/equipment/Equipment.vue';
import EquipmentDetail from '@/js/pages/admin/equipment/EquipmentDetail.vue';
import Deliveries from '@/js/pages/admin/delivery/Deliveries.vue';
import AdminDeliveryDetail from '@/js/pages/admin/delivery/DeliveryDetail.vue';
import Coupons from '@/js/pages/admin/coupon/Coupons.vue';

const routes = [
    {
        path: '/',
        name: 'search',
        component: Search,
        meta: {
            auth: false,
            footer: false,
            navbar: false,
        },
    },
    {
        path: '/selection/:id',
        name: 'selection',
        component: Selection,
        meta: {
            auth: false,
        },
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            auth: true,
        },
    },
    {
        path: '/rents/:id',
        name: 'rentDetail',
        component: RentDetail,
        meta: {
            auth: true,
        },
    },
    {
        path: '/auth/callback',
        name: 'authCallback',
        component: AuthCallback,
        meta: {
            auth: false,
            footer: false,
            navbar: false,
        },
    },
    {
        path: '/auth',
        name: 'auth',
        component: Auth,
        meta: {
            auth: false,
            footer: false,
            navbar: false,
        },
    },
    {
        path: '/admin/users',
        name: 'adminUsers',
        component: AdminUsers,
        meta: {
            auth: true,
            role: ['Admin'],
        },
    },
    {
        path: '/admin/users/:id',
        name: 'adminUserDetail',
        component: AdminUserDetail,
        meta: {
            auth: true,
            role: ['Admin'],
        },
    },
    {
        path: '/admin/rents',
        name: 'adminRents',
        component: Rents,
        meta: {
            auth: true,
            role: ['Admin'],
        },
    },
    {
        path: '/admin/rents/:id',
        name: 'adminRentDetail',
        component: AdminRentDetail,
        meta: {
            auth: true,
            role: ['Admin'],
        },
    },
    {
        path: '/admin/deliveries',
        name: 'adminDeliveries',
        component: Deliveries,
        meta: {
            auth: true,
            role: ['Admin'],
        },
    },
    {
        path: '/admin/deliveries/:id',
        name: 'adminDeliveryDetail',
        component: AdminDeliveryDetail,
        meta: {
            auth: true,
            role: ['Admin'],
        },
    },
    {
        path: '/admin/vehicles',
        name: 'adminVehicles',
        component: Vehicles,
        meta: {
            auth: true,
            role: ['Admin'],
        },
    },
    {
        path: '/admin/vehicles/:id',
        name: 'adminVehicleDetail',
        component: AdminVehicleDetail,
        meta: {
            auth: true,
            role: ['Admin'],
        },
    },
    {
        path: '/admin/equipment',
        name: 'adminEquipment',
        component: Equipment,
        meta: {
            auth: true,
            role: ['Admin'],
        },
    },
    {
        path: '/admin/equipment/:id',
        name: 'adminEquipmentDetail',
        component: EquipmentDetail,
        meta: {
            auth: true,
            role: ['Admin'],
        },
    },
    {
        path: '/admin/coupons',
        name: 'adminCoupons',
        component: Coupons,
        meta: {
            auth: true,
            role: ['Admin'],
        },
    },
];

export default routes;
