<template>
    <div>
        <loading-spinner v-if="loading" />
        <transition name="fade">
            <div v-if="!loading">
                <div class="mx-8 lg:mx-32">
                    <div
                        class="flex mb-16 mt-16 flex-col lg:flex-row lg:items-center mx-0 xl:mx-20 2xl:mx-40"
                    >
                        <div class="flex flex-col items-center">
                            <img
                                :src="rent.vehicle.details.image"
                                class="w-40 lg:w-80"
                            >
                            <span class="flex flex-row">
                                <span class="flex h-3 w-3 m-auto">
                                    <span
                                        :class="colorizeStatus(rent.status, 'bg')"
                                        class="animate-ping absolute inline-flex h-3 w-3 rounded-full opacity-75"
                                    />
                                    <span
                                        :class="colorizeStatus(rent.status, 'bg')"
                                        class="relative inline-flex rounded-full h-3 w-3"
                                    />
                                </span>
                                <span class="ml-4 font-bold">{{
                                    localizeStatus(rent.status)
                                }}</span>
                            </span>
                        </div>
                        <div class="lg:pl-32 flex flex-col items-center w-full">
                            <div
                                class="flex items-center flex-col lg:flex-row lg:items-baseline mb-2 text-2xl text-center font-bold"
                            >
                                {{ rent.vehicle.name }}
                            </div>
                            <table>
                                <tr>
                                    <td class="font-bold">
                                        Mietnummer:
                                    </td>
                                    <td class="text-right">
                                        {{ rent.id }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-bold">
                                        Fahrzeugnummer:
                                    </td>
                                    <td class="text-right">
                                        {{ rent.vehicle.vin }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-bold">
                                        Kennzeichen:
                                    </td>
                                    <td class="text-right">
                                        {{ rent.vehicle.plate || 'Nicht zugewiesen' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-bold">
                                        Zeitraum:
                                    </td>
                                    <td class="text-right">
                                        <span v-tooltip.bottom="formatDate(rent.active_from, true)">{{ formatDate(rent.active_from) }}</span> -
                                        <span v-tooltip.bottom="formatDate(rent.active_to, true)">{{ formatDate(rent.active_to) }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-bold">
                                        Versicherung:
                                    </td>
                                    <td
                                        class="text-right"
                                        v-html="insurance.name"
                                    />
                                </tr>
                                <tr>
                                    <td class="font-bold">
                                        Steuersatz:
                                    </td>
                                    <td
                                        class="text-right"
                                    >
                                        {{ rent.tax_rate }}%
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-bold">
                                        Mindeststunden:
                                    </td>
                                    <td
                                        class="text-right"
                                    >
                                        {{ rent.minimum_hours }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-bold">
                                        Stundenguthaben:
                                    </td>
                                    <td
                                        class="text-right"
                                    >
                                        {{ rent.hour_credit }}
                                    </td>
                                </tr>
                            </table>
                            <div v-if="user.active_coupons.length === 0 && rent.status < 4">
                                <button
                                    class="flex items-center hover:bg-red hover:bg-opacity-10 focus:outline-none p-1 rounded mt-2"
                                    @click="showCouponForm = true"
                                >
                                    <div class="bg-red rounded">
                                        <img
                                            class="w-8"
                                            src="/img/icons/add.png"
                                        >
                                    </div>
                                    <span class="mx-4 font-bold text-sm">Gutschein einlösen</span>
                                </button>
                                <coupon-redeem-form
                                    :show="showCouponForm"
                                    :user-id="rent.user_id"
                                    :rent-id="rentId"
                                    @fetch="fetchedCoupon"
                                    @close="showCouponForm = false"
                                />
                            </div>
                            <coupon-badge
                                v-if="rent.active_coupons.length > 0"
                                class="mt-2"
                                :coupon="rent.active_coupons[0]"
                                :admin="true"
                                @delete="deletedCoupon"
                            />
                        </div>
                    </div>
                </div>
                <div class="bg-gray-100 px-8 lg:px-32 py-14 flex flex-col">
                    <p class="text-red font-bold text-xl text-center lg:text-left mb-4">
                        Mieter
                    </p>
                    <div class="flex justify-between items-center w-full xl:w-4/5 mx-auto">
                        <div class="text-center hidden sm:block">
                            <span class="font-bold">ID</span><br>
                            {{ user.id }}
                        </div>
                        <div class="text-center hidden sm:block">
                            <span class="font-bold">M3 Kundennummer</span><br>
                            {{ user.m3_user_id || 'Nicht zugewiesen' }}
                        </div>
                        <div class="text-center font-bold sm:font-normal">
                            <span class="font-bold hidden sm:block">Name<br></span>
                            {{ user.firstname + ' ' + user.lastname }}
                        </div>
                        <div class="text-center hidden md:block">
                            <span class="font-bold">E-Mail</span><br>
                            {{ user.email }}
                        </div>
                        <router-link :to="{ name: 'adminUserDetail', params: {id: user.id}}">
                            <button class="bg-red p-1 rounded focus:outline-none">
                                <img
                                    class="w-8"
                                    src="/img/icons/pen.png"
                                >
                            </button>
                        </router-link>
                    </div>
                </div>
                <equipment-table
                    :data="equipment"
                    :is-admin="true"
                    :rent-id="rentId"
                    class="px-8 lg:px-32 py-14"
                    @create="addRentEquipment"
                    @delete="removeRentEquipment"
                    @update="updateRentEquipment"
                />
                <delivery-table
                    :data="deliveries"
                    :is-admin="true"
                    :rent-id="rentId"
                    :user="user"
                    class="bg-gray-100 px-8 lg:px-32 py-14"
                    @create="deliveries.push($event)"
                />
                <price-table
                    :id="rent.id"
                    :data="prices"
                    :is-admin="true"
                    class="px-8 lg:px-32 py-14"
                    type="rent"
                    @create="addPrice"
                    @delete="deletePrice"
                />
                <invoice-table
                    :data="invoices"
                    class="bg-gray-100 px-8 lg:px-32 py-14"
                />
                <usage-component
                    :rent-id="rentId"
                    class="px-8 lg:px-32 py-14"
                />
                <discount-table
                    :data="discounts"
                    :rent="rent"
                    class="bg-gray-100 px-8 lg:px-32 py-14"
                    @create="addDiscount"
                />
                <pause-table
                    :data="pauses"
                    class="px-8 lg:px-32 py-14"
                    :is-admin="true"
                    :rent="rent"
                    @create="addPause"
                    @update="updatePause"
                    @delete="removePause"
                />
                <div
                    class="px-8 lg:px-32 py-14 flex items-center sm:justify-center flex-col sm:flex-row"
                >
                    <button
                        class="bg-red text-white px-6 py-2 text-lg sm:mr-2 mb-2 sm:mb-0"
                        @click="showForm = true"
                    >
                        Miete bearbeiten
                    </button>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div
                v-if="showForm"
                class="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-20 p-4 overflow-y-auto"
                @click="showForm = false"
            >
                <div
                    class="bg-white mt-20 rounded-xl max-w-3xl p-8 mx-auto"
                    @click.stop=""
                >
                    <div class="relative">
                        <svg
                            class="absolute right-0 top-0 cursor-pointer"
                            height="24"
                            version="1.0"
                            viewBox="0 0 64 64"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                            @click="showForm = false"
                        >
                            <g
                                fill="#000000"
                                stroke="none"
                                transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                            >
                                <path
                                    d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                                />
                            </g>
                        </svg>
                    </div>
                    <span class="font-bold text-lg">Bearbeite Miete {{ rent.id }}</span>
                    <div class="mt-4">
                        <div>
                            <div>
                                <label
                                    class="font-bold"
                                >Status</label>
                                <div
                                    v-show="availableStatus.length === 0"
                                    id="status"
                                    class="h-full border-2 w-full rounded-md text-gray-700 py-2 px-4 bg-gray-100"
                                >
                                    {{ localizeStatus(rent.status) }}
                                </div>
                                <div
                                    v-show="availableStatus.length > 0"
                                    :class="[(!validations.status) ? 'border-red' : 'border-gray-200']"
                                    class="dropdown inline-block relative h-full border-2 w-full rounded-md"
                                >
                                    <button
                                        class="bg-gray-100 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center justify-between w-full"
                                        type="button"
                                    >
                                        <span class="mr-1">{{ localizeStatus(form.status) }}</span>
                                        <svg
                                            class="fill-current h-4 w-4"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                            />
                                        </svg>
                                    </button>
                                    <ul class="dropdown-menu absolute hidden text-gray-700 pt-1 w-full">
                                        <li
                                            v-for="(status,index) in availableStatus"
                                            :key="index"
                                        >
                                            <a
                                                :class="{'rounded-t': (index === 0), 'rounded-b': (index === (availableStatus.length-1))}"
                                                class="bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap w-full"
                                                @click="form.status = status"
                                            >{{ localizeStatus(status) }}</a>
                                        </li>
                                    </ul>
                                </div>
                                <span
                                    v-show="(rent.status === 2) && (user.m3_user_id === null)"
                                    class="text-red font-bold"
                                >Derzeit ist keine M3 Kundennummer zugewiesen</span>
                            </div>
                            <div v-show="rent.status < 3">
                                <label
                                    class="font-bold"
                                    for="active_from"
                                >Startdatum:</label>
                                <input
                                    id="active_from"
                                    v-model="form.active_from"
                                    :class="[(!validations.active_from) ? 'border-red' : 'border-gray-200']"
                                    class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                                    type="date"
                                >
                            </div>
                            <div v-show="rent.status < 4">
                                <label
                                    class="font-bold"
                                    for="active_to"
                                >Enddatum:</label>
                                <input
                                    id="active_to"
                                    v-model="form.active_to"
                                    :class="[(!validations.active_to) ? 'border-red' : 'border-gray-200']"
                                    class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                                    type="date"
                                >
                            </div>
                            <div>
                                <label
                                    class="font-bold"
                                    for="estimated_hours"
                                >Erwartete Stunden pro Woche</label>
                                <input
                                    id="estimated_hours"
                                    v-model="form.estimated_hours"
                                    class="w-full p-2 border-2 rounded-md my-1"
                                    placeholder="10"
                                    type="text"
                                >
                            </div>
                            <div v-show="rent.status < 2">
                                <label
                                    class="font-bold"
                                    for="tax_rate"
                                >Steuersatz (%)</label>
                                <input
                                    id="tax_rate"
                                    v-model="form.tax_rate"
                                    class="w-full p-2 border-2 rounded-md my-1"
                                    placeholder="0"
                                    step="0.01"
                                    type="number"
                                >
                            </div>
                            <div>
                                <label
                                    class="font-bold"
                                    for="tax_rate"
                                >Mindeststunden</label>
                                <input
                                    id="minimum_hours"
                                    v-model="form.minimum_hours"
                                    class="w-full p-2 border-2 rounded-md my-1"
                                    placeholder="10"
                                    type="number"
                                >
                            </div>
                            <div>
                                <label
                                    class="font-bold"
                                    for="vehicle_id"
                                >Fahrzeug ID</label>
                                <input
                                    id="vehicle_id"
                                    v-model="form.vehicle_id"
                                    :class="[(!validations.vehicle_id) ? 'border-red' : 'border-gray-200']"
                                    class="w-full p-2 border-2 rounded-md my-1"
                                    min="0"
                                    placeholder="0"
                                    type="number"
                                >
                            </div>
                            <div v-show="form.status === 6 || form.status === 7">
                                <label
                                    class="font-bold"
                                    for="cancel_reason"
                                >Grund der Stornierung</label>
                                <input
                                    id="cancel_reason"
                                    v-model="form.cancel_reason"
                                    class="w-full p-2 border-2 rounded-md my-1"
                                    placeholder="Grund der Stornierung"
                                    type="text"
                                >
                            </div>
                        </div>
                        <div class="flex justify-center mt-4">
                            <spin-button
                                :loading="sendingRequest"
                                class="bg-red text-white w-52 h-12"
                                text="Änderung speichern"
                                @btnClick="sendRequest"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import {
    colorizeStatus,
    getRent,
    getRentDeliveries,
    getRentDiscounts,
    getRentEquipment,
    getRentInvoices,
    getRentPauses,
    getRentPrices,
    localizeStatus,
    updateRent,
} from '@/js/services/rent.service';
import { getInsurance } from '@/js/services/insurance.service';
import { getUser } from '@/js/services/user.service';
import { formatDate, formatNumber, isValidDate } from '@/js/services/helper.service';

import LoadingSpinner from '@/js/components/generic/LoadingSpinner.vue';
import PriceTable from '@/js/components/page/price/PriceTable.vue';
import InvoiceTable from '@/js/components/page/rentDetail/InvoiceTable.vue';
import UsageComponent from '@/js/components/page/rentDetail/UsageComponent.vue';
import SpinButton from '@/js/components/generic/buttons/SpinButton.vue';
import EquipmentTable from '@/js/components/page/rentDetail/EquipmentTable.vue';
import DeliveryTable from '@/js/components/page/rentDetail/DeliveryTable.vue';
import PauseTable from '@/js/components/page/rentDetail/PauseTable.vue';
import DiscountTable from '@/js/components/page/rentDetail/DiscountTable.vue';
import CouponRedeemForm from '@/js/components/forms/CouponRedeemForm.vue';
import CouponBadge from '@/js/components/page/rentDetail/CouponBadge.vue';

const { DateTime } = require('luxon');

export default {
    name: 'RentDetail',
    components: {
        CouponBadge,
        CouponRedeemForm,
        LoadingSpinner,
        PriceTable,
        InvoiceTable,
        UsageComponent,
        SpinButton,
        EquipmentTable,
        DeliveryTable,
        PauseTable,
        DiscountTable,
    },
    data() {
        return {
            loading: true,
            sendingRequest: false,
            showForm: false,
            rent: {},
            user: {},
            invoices: [],
            prices: [],
            pauses: [],
            equipment: [],
            deliveries: [],
            discounts: [],
            oldForm: {},
            insurance: {
                name: '',
            },
            form: {
                status: 0,
                active_from: '',
                active_to: '',
                vehicle_id: 0,
                estimated_hours: 0,
                cancel_reason: '',
                tax_rate: 0,
                minimum_hours: 0,
            },
            validations: {
                active_from: true,
                active_to: true,
                vehicle_id: true,
                status: true,
            },
            showCouponForm: false,
        };
    },
    computed: {
        availableStatus() {
            if (this.rent.status >= 5 || this.rent.status === 1 || this.rent.status === 4) return [];
            if (this.rent.status === 0) return [0, 1, 7];
            if ((this.rent.status === 2) && (this.user.m3_user_id === null)) return [6];
            if (this.rent.status === 2) return [2, 3, 6];
            return [this.rent.status, this.rent.status + 1];
        },
        rentId() {
            return parseInt(this.$route.params.id, 10);
        },
    },
    mounted() {
        // Check if needed params are passed
        if (this.rentId != this.$route.params.id) this.$router.push({ name: 'dashboard' }); // eslint-disable-line eqeqeq

        const rentPromise = getRent(this.rentId)
            .then((rent) => {
                this.updateForm(rent);

                const userPromise = getUser(rent.user_id);
                const insurancePromise = getInsurance(rent.insurance_id);

                return Promise.all([userPromise, insurancePromise])
                    .then((result) => {
                        [this.user, this.insurance] = result;

                        return rent;
                    });
            });

        const invoicePromise = getRentInvoices(this.rentId);
        const pricePromise = getRentPrices(this.rentId)
            .then((prices) => prices.sort((a, b) => new Date(a.active_from) - new Date(b.active_from)));
        const equipmentPromise = getRentEquipment(this.rentId)
            .then((rentEquipment) => rentEquipment.sort((a, b) => new Date(a.active_from) - new Date(b.active_from)));
        const deliveryPromise = getRentDeliveries(this.rentId, { opt_fields: ['vehicles', 'equipment'] });
        const pausesPromise = getRentPauses(this.rentId);
        const discountPromise = getRentDiscounts(this.rentId);

        Promise.all([rentPromise, invoicePromise, pricePromise, equipmentPromise, deliveryPromise, pausesPromise, discountPromise])
            .then((result) => {
                [this.rent, this.invoices, this.prices, this.equipment, this.deliveries, this.pauses, this.discounts] = result;
                this.loading = false;
            })
            .catch(() => {
                this.$router.push({ name: 'dashboard' });
            });
    },
    methods: {
        formatNumber,
        updateForm(rent) {
            this.form = {
                status: rent.status,
                estimated_hours: rent.estimated_hours,
                active_from: DateTime.fromISO(rent.active_from)
                    .toISODate(), // apply timezone
                active_to: DateTime.fromISO(rent.active_to)
                    .toISODate(), // apply timezone
                vehicle_id: rent.vehicle.id,
                cancel_reason: rent.cancel_reason,
                tax_rate: rent.tax_rate,
                minimum_hours: rent.minimum_hours,
            };
            this.oldForm = { ...this.form };
        },
        localizeStatus(status) {
            return localizeStatus(status);
        },
        colorizeStatus(status, prefix) {
            return colorizeStatus(status, prefix);
        },
        formatDate(date, includeTime) {
            return formatDate(date, includeTime);
        },
        /**
         * May be used to set old time, as date inputs set the time to 0
         */
        setOldTime(date, dateTime) {
            const dateObj = DateTime.fromISO(date);
            const dateTimeObj = DateTime.fromISO(dateTime);

            return dateObj.set({
                hour: dateTimeObj.hour,
                minute: dateTimeObj.minute,
                second: dateTimeObj.second,
                millisecond: dateTimeObj.millisecond,
            })
                .toISO();
        },
        sendRequest() {
            if (JSON.stringify(this.form) === JSON.stringify(this.oldForm)) {
                this.showForm = false;
                return;
            }

            this.validations.active_from = isValidDate(this.form.active_from);
            this.validations.active_to = isValidDate(this.form.active_to);

            if (!this.validations.active_from || !this.validations.active_to) return;
            if (!this.showForm || this.sendingRequest) return;

            this.oldForm = { ...this.form };
            const formattedForm = { ...this.form };

            // Reset time
            formattedForm.active_from = this.setOldTime(formattedForm.active_from, this.rent.active_from);
            formattedForm.active_to = this.setOldTime(formattedForm.active_to, this.rent.active_to);

            this.sendingRequest = true;

            updateRent(this.rent.id, formattedForm)
                .then((result) => {
                    this.rent = result;
                    this.updateForm(result);
                    Object.keys(this.validations)
                        .forEach((key) => {
                            this.validations[key] = true;
                        });
                    this.showForm = false;
                    this.sendingRequest = false;
                })
                .catch((error) => {
                    const errors = error.response.data.error;
                    if (errors) {
                        this.validations = { ...this.validations, ...errors };
                    }
                    this.sendingRequest = false;
                });
        },
        addPrice(price) {
            this.prices.push(price);
            this.prices.sort((a, b) => new Date(a.active_from) - new Date(b.active_from));
        },
        deletePrice(priceId) {
            this.prices = this.prices.filter((price) => price.id !== priceId);
        },
        addRentEquipment(rentEquipment) {
            this.equipment.push(rentEquipment);
            this.equipment.sort((a, b) => new Date(a.active_from) - new Date(b.active_from));
        },
        updateRentEquipment(rentEquipment) {
            this.removeRentEquipment(rentEquipment.id);
            this.addRentEquipment(rentEquipment);
        },
        removeRentEquipment(equipmentId) {
            this.equipment = this.equipment.filter((e) => e.id !== equipmentId);
        },
        removePause(pauseId) {
            this.pauses = this.pauses.filter((e) => e.id !== pauseId);
        },
        setRentPause(pause) {
            if (pause === null) {
                this.pauses[this.pauses.length - 1].deleted_at = new Date();
            } else {
                this.pauses.push(pause);
            }
            this.rent.rent_pause = pause;
        },
        addDiscount(discount) {
            this.discounts.push(discount);
        },
        addPause(pause) {
            this.pauses.push(pause);
        },
        updatePause(pause) {
            this.removePause(pause.id);
            this.addPause(pause);
        },
        fetchedCoupon(coupon) {
            this.user.active_coupons.push(coupon);
            this.rent.active_coupons.push(coupon);
        },
        deletedCoupon(coupon) {
            this.user.active_coupons = this.user.active_coupons.filter((x) => x.id !== coupon.id);
            this.rent.active_coupons = this.rent.active_coupons.filter((x) => x.id !== coupon.id);
        },
    },
};
</script>
