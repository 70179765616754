<template>
    <div>
        <loading-spinner v-if="loading" />
        <transition name="fade">
            <div v-if="!loading">
                <div class="mx-8 lg:mx-32">
                    <div
                        class="flex mb-16 mt-16 flex-col lg:flex-row lg:items-center mx-0 xl:mx-20 2xl:mx-40"
                    >
                        <div class="flex flex-col items-center">
                            <img
                                :src="vehicle.details.image"
                                class="w-40 lg:w-80"
                            >
                        </div>
                        <div class="lg:pl-32 flex flex-col items-center w-full">
                            <div
                                class="flex items-center flex-col lg:flex-row lg:items-baseline mb-2 text-2xl text-center font-bold"
                            >
                                {{ vehicle.name }}
                            </div>
                            <div
                                class="flex justify-between w-full sm:w-3/4 md:3/5 xl:w-2/3 2xl:w-1/2"
                            >
                                <div class="text-center">
                                    <span class="font-bold">Fahrzeugnr.</span><br>
                                    {{ vehicle.vin }}
                                </div>
                                <div class="text-center hidden sm:block">
                                    <span class="font-bold">Projektnr.</span><br>
                                    {{ vehicle.project_id }}
                                </div>
                                <div class="text-center">
                                    <span class="font-bold">Kennzeichen</span><br>
                                    {{ vehicle.plate || 'Nicht zugewiesen' }}
                                </div>
                            </div>
                            <span
                                v-show="vehicle.deleted_at !== null"
                                class="font-bold text-red mt-4"
                            >Das Fahrzeug ist derzeit {{
                                !vehicle.details.is_m3_rentable ? 'im M3 nicht mietbar' : 'gesperrt'
                            }}</span>
                        </div>
                    </div>
                </div>
                <price-table
                    :id="vehicle.id"
                    :data="prices"
                    :is-admin="true"
                    class="bg-gray-100 px-8 lg:px-32 py-14"
                    type="vehicle"
                    @create="addPrice"
                    @delete="deletePrice"
                />
                <vehicle-equipment-table
                    :data="vehicleEquipment"
                    :vehicle-id="vehicle.id"
                    @create="addEquipment"
                    @delete="deleteEquipment"
                />
                <div class="px-2 lg:px-32 py-14 bg-gray-100">
                    <p class="text-red font-bold text-xl text-center lg:text-left mb-4">
                        Aktuelle Mieten
                    </p>
                    <rent-table
                        v-show="rents.length > 0"
                        :data="rents"
                        :show-name="true"
                        type="admin"
                    />
                    <div
                        v-show="rents.length === 0"
                        class="font-bold text-center lg:text-left"
                    >
                        Derzeit sind keine Mieten vorhanden
                    </div>
                </div>
                <vehicle-property-table
                    :data="vehicleProperties"
                    :vehicle-id="vehicle.id"
                    @create="addVehicleProperty"
                    @delete="deleteVehicleProperty"
                />
                <div
                    class="px-8 lg:px-32 py-14 flex items-center sm:justify-center flex-col sm:flex-row sm:space-x-4"
                >
                    <button
                        class="bg-red text-white px-6 py-2 text-lg w-60 mb-2 sm:mb-0 h-12"
                        @click="showForm = true"
                    >
                        Fahrzeug bearbeiten
                    </button>
                    <spin-button
                        v-show="vehicle.details.is_m3_rentable"
                        :loading="sendingDelRequest"
                        :text="(vehicle.deleted_at === null) ? 'Fahrzeug sperren' : 'Fahrzeug entsperren'"
                        class="bg-gray-400 text-white w-52 h-12"
                        @btnClick="setDeleted"
                    />
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div
                v-if="showForm"
                class="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-20 p-4 overflow-y-auto"
                @click="showForm = false"
            >
                <div
                    class="bg-white mt-20 rounded-xl max-w-3xl p-8 mx-auto"
                    @click.stop=""
                >
                    <div class="relative">
                        <svg
                            class="absolute right-0 top-0 cursor-pointer"
                            height="24"
                            version="1.0"
                            viewBox="0 0 64 64"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                            @click="showForm = false"
                        >
                            <g
                                fill="#000000"
                                stroke="none"
                                transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                            >
                                <path
                                    d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                                />
                            </g>
                        </svg>
                    </div>
                    <span class="font-bold text-lg">Bearbeite {{ vehicle.name }}</span>
                    <div class="mt-4">
                        <div>
                            <div>
                                <label
                                    class="font-bold"
                                    for="plate"
                                >Kennzeichen:</label>
                                <input
                                    id="plate"
                                    v-model="form.plate"
                                    :class="[(!validations.plate) ? 'border-red' : 'border-gray-200']"
                                    class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                                    placeholder="KU-1234"
                                    type="text"
                                >
                                <span
                                    v-show="!validations.plate"
                                    class="font-bold text-red"
                                >Das Kennzeichen ist bereits vergeben.</span>
                            </div>
                            <div>
                                <label
                                    class="font-bold"
                                    for="image"
                                >Bild:</label>
                                <input
                                    id="image"
                                    v-model="form.details.image"
                                    class="w-full p-3 mt-1/2 rounded-md border-2 bg-white border-gray-200"
                                    placeholder="Bild"
                                    type="text"
                                >
                            </div>
                            <div>
                                <label
                                    class="font-bold"
                                    for="productpage"
                                >Produktseite:</label>
                                <input
                                    id="productpage"
                                    v-model="form.details.product_page"
                                    class="w-full p-3 mt-1/2 rounded-md border-2 bg-white border-gray-200"
                                    placeholder="Produktseite"
                                    type="text"
                                >
                            </div>
                            <div>
                                <label
                                    class="font-bold"
                                    for="vehtype"
                                >Fahrzeugtyp:</label>
                                <input
                                    id="vehtype"
                                    v-model="form.details.type"
                                    class="w-full p-3 mt-1/2 rounded-md border-2 bg-white border-gray-200"
                                    placeholder="Lintrac"
                                    type="text"
                                >
                            </div>
                            <div>
                                <label
                                    class="font-bold"
                                    for="vehname"
                                >Fahrzeugname:</label>
                                <input
                                    id="vehname"
                                    v-model="form.name"
                                    :class="[(!validations.name) ? 'border-red' : 'border-gray-200']"
                                    class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                                    placeholder="Lintrac 90"
                                    type="text"
                                >
                                <span
                                    v-show="!validations.name"
                                    class="font-bold text-red"
                                >Der Name darf nicht leer sein!</span>
                            </div>
                        </div>
                        <div class="flex justify-center mt-4">
                            <spin-button
                                :loading="sendingRequest"
                                class="bg-red text-white w-52 h-12"
                                text="Änderung speichern"
                                @btnClick="sendRequest"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import {
    deleteVehicle,
    getVehicle,
    getVehicleEquipment,
    getVehiclePrices,
    getVehicleProperties,
    getVehicleRents,
    updateVehicle,
} from '@/js/services/vehicle.service';

import LoadingSpinner from '@/js/components/generic/LoadingSpinner.vue';
import PriceTable from '@/js/components/page/price/PriceTable.vue';
import RentTable from '@/js/components/page/RentTable.vue';
import SpinButton from '@/js/components/generic/buttons/SpinButton.vue';
import VehicleEquipmentTable from '@/js/components/page/VehicleEquipmentTable.vue';
import VehiclePropertyTable from '@/js/components/page/VehiclePropertyTable.vue';

export default {
    name: 'VehicleDetail',
    components: {
        LoadingSpinner,
        PriceTable,
        RentTable,
        SpinButton,
        VehiclePropertyTable,
        VehicleEquipmentTable,
    },
    data() {
        return {
            loading: true,
            sendingRequest: false,
            sendingDelRequest: false,
            showForm: false,
            vehicle: {},
            vehicleProperties: [],
            vehicleEquipment: [],
            prices: [],
            oldForm: {},
            rents: [],
            form: {
                name: '',
                plate: '',
                details: {
                    product_page: '',
                    image: '',
                    type: '',
                },
            },
            validations: {
                plate: true,
                name: true,
            },
        };
    },
    mounted() {
        // Check if needed params are passed
        const vehicleId = parseInt(this.$route.params.id, 10);

        if (vehicleId != this.$route.params.id) this.$router.push({ name: 'dashboard' }); // eslint-disable-line eqeqeq

        // Get vehicle data
        const vehiclePromise = getVehicle(vehicleId)
            .then((vehicle) => {
                this.form = {
                    name: vehicle.name,
                    plate: vehicle.plate,
                    details: {
                        image: vehicle.details.image,
                        product_page: vehicle.details.product_page,
                        type: vehicle.details.type,
                    },
                };
                this.oldForm = JSON.parse(JSON.stringify(this.form)); // Deep shallow copy

                return vehicle;
            });
        const rentsPromise = getVehicleRents(vehicleId, {
            opt_fields: ['user'],
        });
        const pricesPromise = getVehiclePrices(vehicleId)
            .then((prices) => prices.sort((a, b) => new Date(a.active_from) - new Date(b.active_from)));
        const equipmentPromise = getVehicleEquipment(vehicleId);

        Promise.all([vehiclePromise, rentsPromise, pricesPromise, equipmentPromise])
            .then((result) => {
                [this.vehicle, this.rents, this.prices, this.vehicleEquipment] = result;
                this.loading = false;
            })
            .catch(() => {
                // Vehicle not found
                this.$router.push({ name: 'dashboard' });
            });
        getVehiclePrices(vehicleId)
            .then((prices) => {
                this.prices = prices;
                this.prices.sort((a, b) => new Date(a.active_from) - new Date(b.active_from));
            });
        getVehicleProperties(vehicleId)
            .then((result) => {
                this.vehicleProperties = result;
            });
    },
    methods: {
        sendRequest() {
            if (JSON.stringify(this.form) === JSON.stringify(this.oldForm)) {
                this.showForm = false;
                return;
            }
            this.validations.name = this.form.name !== '';

            if (!this.validations.name) return;

            if (!this.showForm || this.sendingRequest) return;

            this.oldForm = JSON.parse(JSON.stringify(this.form)); // Deep shallow copy
            this.sendingRequest = true;

            updateVehicle(this.vehicle.id, this.form)
                .then((result) => {
                    this.vehicle = result;
                    this.validations.plate = true;

                    this.showForm = false;
                    this.sendingRequest = false;
                })
                .catch((error) => {
                    const errors = error.response.data.error;
                    if (errors) {
                        if (errors.plate) {
                            this.validations.plate = false;
                        }
                    }
                    this.sendingRequest = false;
                });
        },
        setDeleted() {
            if (this.sendingDelRequest) return;
            this.sendingDelRequest = true;

            if (this.vehicle.deleted_at === null) {
                deleteVehicle(this.vehicle.id)
                    .then(() => {
                        this.vehicle.deleted_at = new Date();
                        this.sendingDelRequest = false;
                    });
            } else {
                updateVehicle(this.vehicle.id, {
                    deleted_at: null,
                })
                    .then(() => {
                        this.vehicle.deleted_at = null;
                        this.sendingDelRequest = false;
                    });
            }
        },
        addPrice(price) {
            this.prices.push(price);
            this.prices.sort((a, b) => new Date(a.active_from) - new Date(b.active_from));
        },
        deletePrice(priceId) {
            this.prices = this.prices.filter((price) => price.id !== priceId);
        },
        addVehicleProperty(result) {
            this.vehicleProperties.push(result);
        },
        deleteVehicleProperty(propertyId) {
            this.vehicleProperties = this.vehicleProperties.filter((e) => e.id !== propertyId);
        },
        addEquipment(result) {
            this.vehicleEquipment.push(result);
        },
        deleteEquipment(equipmentId) {
            this.vehicleEquipment = this.vehicleEquipment.filter((e) => e.id !== equipmentId);
        },
    },
};
</script>
