import axios from 'axios';

const BASE_URL = `${process.env.MIX_VUE_API_BASE}/rents`;

/**
 * Fetches rents from database
 * @param {Object} params
 * @returns {Promise} API Request
 */
export function getRents(params = {}) {
    return axios.get(`${BASE_URL}`, { params })
        .then((response) => response.data.data);
}

/**
 * Fetches rent by id
 * @param {number} rentId
 * @returns {Promise} API Request
 */
export function getRent(rentId) {
    return axios.get(`${BASE_URL}/${rentId}`)
        .then((response) => response.data.data);
}

/**
 * Fetches rent prices by id
 * @param {number} rentId
 * @returns {Promise} API Request
 */
export function getRentInvoices(rentId) {
    return axios.get(`${BASE_URL}/${rentId}/invoices`)
        .then((response) => response.data.data);
}

/**
 * Fetches rent invoices by id
 * @param {number} rentId
 * @returns {Promise} API Request
 */
export function getRentPrices(rentId) {
    return axios.get(`${BASE_URL}/${rentId}/prices`)
        .then((response) => response.data.data);
}

/**
 * Fetches rent usage by id
 * @param {*} rentId
 * @returns {Promise} API Request
 */
export function getRentUsages(rentId) {
    return axios.get(`${BASE_URL}/${rentId}/usages`)
        .then((response) => response.data.data);
}

/**
 * Creates a new rent equipment
 * @param {number} rentId
 * @param {Object} body
 * @returns {Promise} API Request
 */
export function createRentEquipment(rentId, body = {}) {
    return axios.post(`${BASE_URL}/${rentId}/equipment`, body)
        .then((response) => response.data.data);
}

/**
 * Updates a rent equipment
 * @param {number} rentId
 * @param {number} rentEquipmentId
 * @param {Object} body
 * @returns {Promise} API Request
 */
export function updateRentEquipment(rentId, rentEquipmentId, body = {}) {
    return axios.patch(`${BASE_URL}/${rentId}/equipment/${rentEquipmentId}`, body)
        .then((response) => response.data.data);
}

/**
 * Deletes a rent equipment
 * @param {number} rentId
 * @param {number} rentEquipmentId
 * @param {Object} body
 * @returns {Promise} API Request
 */
export function deleteRentEquipment(rentId, rentEquipmentId) {
    return axios.delete(`${BASE_URL}/${rentId}/equipment/${rentEquipmentId}`)
        .then((response) => response.data.data);
}

/**
 * Creates a new rent (and equipment, if passed)
 * @param {Object} body
 * @returns {Promise} API Request
 */
export function createRent(body = {}, equipment = []) {
    return axios.post(BASE_URL, body)
        .then((response) => {
            const rent = response.data.data;

            const equipmentPromises = [];
            equipment.forEach((equip) => {
                equipmentPromises.push(createRentEquipment(rent.id, {
                    equipment_id: equip.id,
                    active_from: rent.active_from,
                    active_to: rent.active_to,
                }));
            });

            return Promise.all(equipmentPromises)
                .then(() => rent);
        });
}

/**
 * Updates a rent by id
 * @param {number} rentId
 * @param {Object} body
 * @returns {Promise} API Request
 */
export function updateRent(rentId, body = {}) {
    return axios.patch(`${BASE_URL}/${rentId}`, body)
        .then((response) => response.data.data);
}

/**
 * Creates a new rent price
 * @param {number} rentId
 * @param {Object} body
 * @returns {Promise} API Request
 */
export function createRentPrice(rentId, body = {}) {
    return axios.post(`${BASE_URL}/${rentId}/prices`, body)
        .then((response) => response.data.data);
}

/**
 * Deletes a rent price
 * @param {number} rentId
 * @param {number} priceId
 * @returns {Promise} API Request
 */
export function deleteRentPrice(rentId, priceId) {
    return axios.delete(`${BASE_URL}/${rentId}/prices/${priceId}`)
        .then((response) => response.data.data);
}

/**
 * Fetches rent equipment prices by id
 * @param {number} rentId
 * @param {number} rentEquipmentId
 * @returns {Promise} API Request
 */
export function getRentEquipmentPrices(rentId, rentEquipmentId) {
    return axios.get(`${BASE_URL}/${rentId}/equipment/${rentEquipmentId}/prices`)
        .then((response) => response.data.data);
}

/**
 * Creates rent equipment price by id
 * @param {number} rentId
 * @param {number} rentEquipmentId
 * @returns {Promise} API Request
 */
export function createRentEquipmentPrice(rentId, rentEquipmentId, body = {}) {
    return axios.post(`${BASE_URL}/${rentId}/equipment/${rentEquipmentId}/prices`, body)
        .then((response) => response.data.data);
}

/**
 * Deletes rent equipment price by id
 * @param {number} rentId
 * @param {number} rentEquipmentId
 * @returns {Promise} API Request
 */
export function deleteRentEquipmentPrice(rentId, rentEquipmentId, priceId) {
    return axios.delete(`${BASE_URL}/${rentId}/equipment/${rentEquipmentId}/prices/${priceId}`)
        .then((response) => response.data.data);
}

/**
 * Fetches rent equipment by id
 * @param {number} rentId
 * @returns {Promise} API Request
 */
export function getRentEquipment(rentId) {
    return axios.get(`${BASE_URL}/${rentId}/equipment`)
        .then((response) => response.data.data);
}

/**
 * Fetches rent deliveries by id
 * @param {number} rentId
 * @param {object} params
 * @returns {Promise} API Request
 */
export function getRentDeliveries(rentId, params = {}) {
    return axios.get(`${BASE_URL}/${rentId}/deliveries`, { params })
        .then((response) => response.data.data);
}

/**
 * Fetches rent pauses by id
 * @param {number} rentId
 * @param {object} params
 * @returns {Promise} API Request
 */
export function getRentPauses(rentId, params = {}) {
    return axios.get(`${BASE_URL}/${rentId}/pauses`, { params })
        .then((response) => response.data.data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)));
}

/**
 * Creates a rent pause by id
 * @param {number} rentId
 * @param {object} body
 * @returns {Promise} API Request
 */
export function createRentPause(rentId, body = {}) {
    return axios.post(`${BASE_URL}/${rentId}/pauses`, body)
        .then((response) => response.data.data);
}

/**
 * Updates a rent pause by id
 * @param {number} rentId
 * @param {number} rentPauseId
 * @param {object} body
 * @returns {Promise} API Request
 */
export function updateRentPause(rentId, rentPauseId, body = {}) {
    return axios.patch(`${BASE_URL}/${rentId}/pauses/${rentPauseId}`, body)
        .then((response) => response.data.data);
}

/**
 * Fetches a rent pause by id
 * @param {number} rentId
 * @param {number} pauseId
 * @returns {Promise} API Request
 */
export function getRentPause(rentId, pauseId) {
    return axios.get(`${BASE_URL}/${rentId}/pauses/${pauseId}`)
        .then((response) => response.data.data);
}

/**
 * Deletes a rent pause by id
 * @param {number} rentId
 * @param {number} pauseId
 * @returns {Promise} API Request
 */
export function deleteRentPause(rentId, pauseId) {
    return axios.delete(`${BASE_URL}/${rentId}/pauses/${pauseId}`)
        .then((response) => response.data.data);
}

/**
 * Fetches rent discounts by id
 * @param {number} rentId
 * @param {object} params
 * @returns {Promise} API Request
 */
export function getRentDiscounts(rentId, params = {}) {
    return axios.get(`${BASE_URL}/${rentId}/discounts`, { params })
        .then((response) => response.data.data);
}

/**
 * Creates a new rent discount
 * @param {number} rentId
 * @param {object} body
 * @returns {Promise} API Request
 */
export function createRentDiscount(rentId, body = {}) {
    return axios.post(`${BASE_URL}/${rentId}/discounts`, body)
        .then((response) => response.data.data);
}

/**
 * Localizes status enum
 * @param {number} status
 * @returns {string} localized status
 */
export function localizeStatus(status) {
    return ['Angefragt', 'Angebot erhalten', 'Angebot bestätigt', 'Miete aktiv', 'Miete beendet', 'Miete abgeschlossen', 'Angebot abgelehnt', 'Anfrage abgelehnt'][status];
}

/**
 * Colorizes status enum
 * @param {number} status
 * @returns {string} colorized status
 */
export function colorizeStatus(status, prefix) {
    if (!(['bg', 'text'].includes(prefix))) throw new Error('Invalid color prefix provided');

    // HINT: This syntax is used to prevent postcss from purging conditional classes

    if ([0, 1].includes(status)) {
        return {
            text: 'text-orange',
            bg: 'bg-orange',
        }[prefix];
    }

    if ([2, 3, 4, 5].includes(status)) {
        return {
            text: 'text-green',
            bg: 'bg-green',
        }[prefix];
    }
    return {
        text: 'text-red',
        bg: 'bg-red',
    }[prefix];
}
