<template>
    <div>
        <p class="text-red font-bold text-xl text-center lg:text-left mb-4">
            Anbaugeräte
        </p>
        <button
            v-show="isAdmin"
            class="flex items-center hover:bg-red hover:bg-opacity-10 focus:outline-none p-1 rounded mb-4"
            @click="selectedEquipment = {}; showRentEquipmentForm = true"
        >
            <div class="bg-red rounded">
                <img
                    class="w-8"
                    src="/img/icons/add.png"
                >
            </div>
            <span class="mx-4 font-bold text-sm">Anbaugerät hinzufügen</span>
        </button>
        <table
            v-show="data.length > 0"
            class="w-full xl:w-4/5 mx-auto text-center"
        >
            <tr>
                <th class="hidden md:table-cell" />
                <th class="hidden lg:table-cell">
                    ID
                </th>
                <th>Name</th>
                <th class="hidden sm:table-cell">
                    Zeitraum
                </th>
                <th>Tarif</th>
                <th v-show="isAdmin" />
            </tr>
            <tr
                v-for="equipment in data"
                :key="equipment.id"
            >
                <td class="hidden md:table-cell">
                    <img
                        :src="equipment.equipment.details.image"
                        width="80"
                    >
                </td>
                <td class="hidden lg:table-cell">
                    {{ equipment.id }}
                </td>
                <td>
                    {{ equipment.equipment.name }}<br class="sm:hidden">
                    <a
                        :href="equipment.equipment.details.product_page"
                        class="text-white bg-red py-0.5 px-2 text-xs font-roboto"
                    >
                        Details
                    </a>
                </td>
                <td class="hidden sm:table-cell">
                    {{ formatDate(equipment.active_from) }} - {{ formatDate(equipment.active_to) }}
                </td>
                <td v-html="formatPrice(equipment.rent_price.price)" />
                <td v-show="isAdmin">
                    <button
                        class="bg-red p-1 rounded focus:outline-none mr-1"
                        @click="selectedEquipment = equipment; showRentEquipmentForm = true"
                    >
                        <img
                            class="w-7 h-7"
                            src="/img/icons/pen.png"
                        >
                    </button>
                    <button
                        v-show="new Date(equipment.active_from) > new Date()"
                        class="ml-1 bg-red p-1 rounded focus:outline-none"
                        @click="removeRentEquipment(equipment.id)"
                    >
                        <svg
                            class="fill-current text-white w-7 h-7"
                            version="1.0"
                            viewBox="0 0 64 64"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g
                                stroke="none"
                                transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                            >
                                <path
                                    d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                                />
                            </g>
                        </svg>
                    </button>
                </td>
            </tr>
        </table>
        <div
            v-show="data.length === 0"
            class="font-bold text-center lg:text-left"
        >
            Derzeit sind keine Anbaugeräte vorhanden
        </div>
        <rent-equipment-form
            :rent-equipment="selectedEquipment"
            :rent-id="rentId"
            :show="showRentEquipmentForm"
            @close="showRentEquipmentForm = false"
            @create="$emit('create',$event)"
            @update="$emit('update',$event)"
        />
    </div>
</template>
<script>
import { formatDate, formatPrice } from '@/js/services/helper.service';
import RentEquipmentForm from '@/js/components/forms/equipment/RentEquipmentForm.vue';
import { deleteRentEquipment } from '@/js/services/rent.service';

export default {
    name: 'EquipmentTable',
    components: {
        RentEquipmentForm,
    },
    props: {
        rentId: {
            type: Number,
            required: true,
        },
        data: {
            type: Array,
            default: () => [],
        },
        isAdmin: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            showRentEquipmentForm: false,
            selectedEquipment: {},
        };
    },
    methods: {
        formatDate(date) {
            return formatDate(date);
        },
        formatPrice(value) {
            return formatPrice(value);
        },
        removeRentEquipment(equipmentId) {
            deleteRentEquipment(this.rentId, equipmentId)
                .then(() => {
                    this.$emit('delete', equipmentId);
                });
        },
    },
};
</script>
